import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
import Tracking from '../../../src/Tracking';
import useDimension from '../../../src/hooks/use-dimension';
import StaticImage from '../../../src/Components/StaticImage';
import {navigate} from 'gatsby';
import {Button} from '../../../src/Widgets';
const appConfig = require('../data.json');

// const RATIO = 310 / 395;
const gap = 20;

function Promo({promoZone, ratio}) {
  useEffect(() => {
    Tracking.addPromotionImpression(promoZone);
  }, [promoZone]);

  return (
    <a
      target={'_blank'}
      rel="noreferrer"
      href={promoZone.link}
      onClick={() => {
        Tracking.addPromotionImpressionClick(promoZone);
      }}>
      <ImageWrapper ratio={ratio}>
        <img src={`${promoZone.image}`} alt="not found" />
      </ImageWrapper>
    </a>
  );
}

export default function PromoZoneTopSection(props) {
  const wrapperRef = useRef();
  let {promoZones} = props;
  const [itemSize, setItemSize] = useState(0);
  const {dimension} = useDimension();

  const promos = useMemo(() => {
    return promoZones
      .concat(Array.from({length: 3}, () => ({link: '', image: ''})))
      .slice(0, 3)
      .sort((a, b) => {
        if (a.priority === null) {
          return 1;
        } else if (b.priority === null) {
          return -1;
        }
        return a.priority - b.priority;
      });
  }, [promoZones]);

  useEffect(() => {
    // to get unit size
    if (wrapperRef.current) {
      let {width} = wrapperRef.current.getBoundingClientRect();
      let length = 4;
      let size = (width - (length - 1) * gap) / length;

      if (dimension.innerWidth <= appConfig.breakpoints.sm) {
        length = 2;
        size = width / length;
      }

      setItemSize(size);
    }
  }, [wrapperRef, dimension.innerWidth]);

  return (
    <Wrapper
      ref={(ref) => {
        wrapperRef.current = ref;
      }}
      size={itemSize}
      gap={gap}>
      <div className="item item-1" key="1">
        <Promo promoZone={promos[0]} ratio={1 / 1} />
      </div>
      <div className="item item-2" key="2">
        <Promo promoZone={promos[1]} ratio={1 / 2} />
      </div>
      <div className="item item-3" key="3">
        <Promo promoZone={promos[2]} ratio={1 / 2} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, ${(props) => props.size}px);
  grid-template-rows: repeat(2, ${(props) => props.size}px);
  grid-template-areas: 'item1 item1 item2 item2' 'item1 item1 item3 item3';
  grid-gap: ${(props) => props.gap}px;
  justify-content: stretch;
  & > .item {
    overflow: hidden;
    background-color: #f0f0f0;
  }

  & > .item-1 {
    grid-area: item1;
  }
  & > .item-2 {
    grid-area: item2;
  }
  & > .item-3 {
    grid-area: item3;
  }

  @media only screen and (max-width: ${appConfig.breakpoints.sm}px) {
    grid-template-columns: ${(props) => props.size * 2}px;
    grid-template-rows: repeat(4, ${(props) => props.size}px);
    grid-template-areas: 'item1' 'item1' 'item2' 'item3';
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${(props) => props.ratio * 100}%;

  > img {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`;
