import React from 'react';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

const social_icons = [
  {
    image: 'home-social-fb.png',
    link: appConfig.socialMedia.facebook.link,
  },
  {
    image: 'home-social-line.png',
    link: appConfig.socialMedia.line.link,
  },
  {
    image: 'home-social-ig.png',
    link: appConfig.socialMedia.instagram.link,
  },
];

function SocialMediaSection(props) {
  return (
    <Wrapper>
      {social_icons.map((icon, idx) => (
        <a
          className={'social-icon'}
          key={idx}
          href={`${icon.link}`}
          target="_blank"
          rel="noreferrer">
          <StaticImage filename={`${icon.image}`} />
        </a>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  & > .social-icon {
    flex: 1 1 30%;
  }
  @media screen and (max-width: 600px) {
    & > .social-icon {
      flex: 1 0 100%;
    }
    & > .social-icon:nth-child(2) {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export default SocialMediaSection;
