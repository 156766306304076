import React, {useContext, useCallback, useState, useEffect} from 'react';
import styled from 'styled-components';
import {Button, Row, Col} from 'antd';
import {navigate} from 'gatsby';
import {Context} from '../../../src/AppContext';
import useDimension from '../../../src/hooks/use-dimension';
import StaticImage from '../../../src/Components/StaticImage';
import ProductItem from '../../../src/Components/ProductItem';
import BlogItem from '../../../src/Components/BlogItem';
import Carousel from './BannerCarousel';
import PromoZoneTopSection from './PromoZoneTopSection';
import PromoZoneBottomSection from './PromoZoneBottomSection';
import AboutSection from './AboutSection';
import SocialMediaSection from './SocialMediaSection';
const appConfig = require('../../../src/data.json');
const slugify = require('uslug');

export default function LandingPage(props) {
  const app = useContext(Context);
  const {promoItems} = app.state;
  const {dimension} = useDimension();
  const [products, setProducts] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const getProducts = useCallback(async () => {
    try {
      let resp = await app.actions.getProducts();
      setProducts(
        resp.filter((p) => app.state.productNames.indexOf(p.name) > -1),
      );
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions, app.state.productNames]);

  const getBlogs = useCallback(async () => {
    try {
      let resp = await app.actions.getBlogs({
        query: {
          $and: [
            {
              label: {$not: {$regex: 'FAQ'}},
            },
            {
              label: {$not: {$regex: 'draft'}},
            },
          ],
        },
        sorting: ['-created'],
      });
      setBlogs(resp);
    } catch (ex) {
      console.warn(ex);
    }
  }, [app.actions]);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      await Promise.all([getProducts(), getBlogs()]);
      app.actions.setLoading(false);
    })();
  }, [app.actions, getProducts, getBlogs]);

  return (
    <Wrapper>
      <div className="banner-container">
        <Carousel imagesArray={promoItems.filter((x) => x.type === 'banner')} />
      </div>

      <div className="topzone-section section center-content">
        <p className="title">日常摺理 · Infoli Studio</p>
        <PromoZoneTopSection
          promoZones={promoItems.filter((x) => x.type === 'top_zone')}
        />
      </div>

      <div style={{marginBottom: 60}}>
        <AboutSection />
      </div>

      <div className="center-content">
        <div className="section">
          <p className="title">熱門商品 · Best Sellers</p>
          <Row
            gutter={[appConfig.productGridGutter, appConfig.productGridGutter]}
            style={{alignSelf: 'stretch'}}>
            {products
              .filter((p) => p.tag === 'HOT')
              .slice(0, 8)
              .map((p, idx) => {
                return (
                  <Col xs={12} md={8} lg={6} key={idx}>
                    <ProductItem
                      product={p}
                      onClick={() => {
                        navigate(`/product/${slugify(p.name)}`);
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>

      <div
        style={{
          marginBottom: 60,
          backgroundColor: '#ededed',
          padding: dimension.innerWidth <= appConfig.breakpoints.lg ? 20 : 80,
        }}>
        <PromoZoneBottomSection
          promoZones={promoItems.filter((x) => x.type === 'bottom_zone')}
        />
      </div>
      <div className="center-content">
        <div className="section">
          <p className="title">新品上市 · New Arrivals</p>
          <Row
            gutter={[appConfig.productGridGutter, appConfig.productGridGutter]}
            style={{alignSelf: 'stretch'}}>
            {products
              .filter((p) => p.tag === 'NEW')
              .slice(0, 8)
              .map((p, idx) => {
                return (
                  <Col xs={12} md={8} lg={6} key={idx}>
                    <ProductItem
                      product={p}
                      onClick={() => {
                        navigate(`/product/${slugify(p.name)}`);
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>

      <div className="center-content">
        <div className="section">
          <p className="title">最新文章 · Blog</p>
          <Row gutter={20} style={{alignSelf: 'stretch'}}>
            {blogs.slice(0, 3).map((record) => (
              <Col xs={24} md={8} key={record.id}>
                <BlogItem record={record} extraStyle={{}} type="portrait" />
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <div>
        <SocialMediaSection />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;

  .banner-container {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 80px;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      padding-top: 0px;
    }
  }

  .center-content {
    max-width: ${appConfig.maxContentWidth}px;
    margin: 0 auto 80px auto;
    padding: 0px 15px;
  }

  & .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    & > .title {
      text-align: center;
      font-size: 22px;
      color: ${appConfig.colors.main};
      margin-bottom: 45px;
    }
  }

  & .topzone-section {
    overflow: hidden;
    margin-bottom: 100px;
  }
`;
