import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate} from 'gatsby';

function AboutSection(props) {
  return (
    <Wrapper>
      <div>
        <h3>日常摺理 infoli.</h3>
        <p>
          {`MIT台灣品牌 —「日常摺理 INFOLI」品牌工藝所，期待能透過創造、定製並體驗手感所帶來的快樂。\n我們以小批量專注品質生產，製品皆透過台灣師傅手工打造經典，喜歡保持簡單，讓美麗的材料為自己說話。\n一件優秀的筆記手本，需要能夠乘載我們書寫、素描和塗鴉的需求，不受使用而質變，並透過紙張與筆尖紀錄，\n梳理日常紊亂的思緒，抒發強大有力的想法。日常摺理 INFOLI的客製筆記手本是專為您而設，每件手本皆依據您的日常需求而專屬打造！`}
        </p>
        <Button type="primary" onClick={() => navigate('/about')}>
          ABOUT INFOLI
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 60px 10px 80px 10px;

  background-image: url('/images/landing-about.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-center: center;

  & > div {
    max-width: 854px;

    width: 100%;
    text-align: center;

    & > h3 {
      margin: 0px;
      margin-bottom: 30px;
      font-size: 28px;
      font-weight: 300;
      line-height: 1.46;
    }

    & > p {
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 2.29;
      white-space: pre-wrap;
    }
  }
`;

export default AboutSection;
