import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {ArrowLeft, ArrowRight} from '@styled-icons/bootstrap';
import Carousel from 'nuka-carousel';
import useDimension from '../../../src/hooks/use-dimension';
import Tracking from '../../../src/Tracking';
const appConfig = require('../../../src/data.json');

function Promo({promoZone}) {
  const {dimension} = useDimension();

  useEffect(() => {
    Tracking.addPromotionImpression(promoZone);
  }, [promoZone]);

  return (
    <Image
      image={`${promoZone.image}`}
      height={
        dimension.innerWidth > appConfig.breakpoints.sm ? '456px' : '300px'
      }></Image>
  );
}

export default function PromoZoneBottomSection(props) {
  const {promoZones} = props;
  let [slideIndex, setSlideIndex] = React.useState(0);
  let _promoZones = promoZones.sort((a, b) => {
    if (a.priority === null) {
      return 1;
    } else if (b.priority === null) {
      return -1;
    }
    return a.priority - b.priority;
  });

  const renderLeftIcon = ({previousSlide}) => (
    <IconContainer
      onClick={previousSlide}
      aria-label="Prev Banner"
      css="margin-left: 15px;">
      <ArrowLeft color="gray" style={{width: 22, height: 22}} />
    </IconContainer>
  );

  const renderRightIcon = ({nextSlide}) => (
    <IconContainer
      onClick={nextSlide}
      aria-label="Next Banner"
      css="margin-right: 15px;">
      <ArrowRight color="gray" style={{width: 22, height: 22}} />
    </IconContainer>
  );

  return (
    <Wrapper>
      <div className="intro">
        <div className="title">活動專區 · Promo Zone</div>
        <div className="label">
          {(_promoZones[slideIndex] && _promoZones[slideIndex].label) || '-'}
        </div>
      </div>
      <Carousel
        width={'100%'}
        autoplay
        renderBottomCenterControls={(props) => <Indicator {...props} />}
        renderCenterLeftControls={renderLeftIcon}
        renderCenterRightControls={renderRightIcon}
        slideIndex={slideIndex}
        afterSlide={(slideIndex) => setSlideIndex(slideIndex)}>
        {_promoZones.map((promoZone, idx) => (
          <Promo promoZone={promoZone} key={idx} />
        ))}
      </Carousel>
    </Wrapper>
  );
}

function Indicator(props) {
  let {currentSlide, goToSlide, slideCount} = props;
  let indexes = Array.from(Array(slideCount).keys());
  return (
    <IndicatorWrapper>
      {indexes.map((index) => (
        <IndicatorLine
          key={index}
          selected={index === currentSlide}
          onClick={() => goToSlide(index)}
        />
      ))}
    </IndicatorWrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
  display: flex;

  & > .intro {
    align-self: center;
    margin-right: 60px;
    max-width: 250px;
    width: 100%;

    & > .title {
      margin-bottom: 32px;
      text-align: center;
      font-size: 22px;
      color: ${appConfig.colors.main};
    }

    & > .label {
      font-size: 28px;
      line-height: 1.46;
    }
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-direction: column;
    padding-bottom: 50px;

    & > .intro {
      margin-right: 0px;
      margin-bottom: 30px;
      max-width: 500px;
      text-align: center;

      & > .label {
        font-size: 24px;
      }
    }
  }
`;

const Image = styled.div`
  position: relative;
  cursor: pointer;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e0e0e0;
  height: ${(props) => props.height || '200px'};

  &:hover {
    opacity: 0.75;
  }
`;

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.45);
  border: 0px;
  width: 35px;
  height: 35px;

  :hover {
    background-color: rgba(255, 255, 255, 0.7);
  }

  :focus {
    outline: none;
  }
`;

const IndicatorWrapper = styled.div`
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    bottom: -30px;
  }
`;

const IndicatorLine = styled.div`
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? appConfig.colors.third : '#ffffff'};
  width: 60px;
  height: 10px;
  margin-left: 11px;

  & :first-child {
    margin-left: 0px;
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    width: 40px;
  }

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    width: 35px;
  }
`;
