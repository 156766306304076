import React, {Fragment} from 'react';
import styled from 'styled-components';
const config = require('../data.json');

function getColor(tag) {
  let color = config.colors.main;
  const dic = {
    is_out_stock: config.colors.error,
    HOT: '#EB5554',
    SALE: '#C89664',
    NEW: '#8AC262',
  };
  return dic[tag] || color;
}

export default function ProductTag({product, freeShipping = false, onItem}) {
  const Tag = onItem ? TagOnItem : TagInDetail;

  if (!product) {
    return null;
  }
  const outOfStock = product.is_out_stock;
  let tags = [];

  if (onItem) {
    // only one
    if (outOfStock) {
      tags = [
        <Tag color={getColor('is_out_stock')} key="is_out_stock">
          售完
        </Tag>,
      ];
    } else if (freeShipping) {
      tags = [
        <Tag color={getColor('')} key="free_shipping">
          免運
        </Tag>,
      ];
    } else if (product.tag) {
      tags = [
        <Tag color={getColor(product.tag)} key={product.tag}>
          {product.tag}
        </Tag>,
      ];
    }
  } else {
    // all
    if (outOfStock) {
      tags.push(
        <Tag color={getColor('is_out_stock')} key="is_out_stock">
          售完
        </Tag>,
      );
    }
    if (freeShipping) {
      tags.push(
        <Tag color={getColor('')} key="free_shipping">
          免運
        </Tag>,
      );
    }
    if (product.tag) {
      tags.push(
        <Tag color={getColor(product.tag)} key={product.tag}>
          {product.tag}
        </Tag>,
      );
    }
  }

  return tags.length > 0 ? tags : null;
}

const TagOnItem = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px 8px;
  font-size: 12px;
  color: white;
  border-bottom-right-radius: 8px;
  background-color: ${(props) => props.color};
`;

const TagInDetail = styled.div`
  margin-left: 5px;
  padding: 2px 7px;
  display: inline-block;
  color: white;
  border-radius: 8px;
  background-color: red;
  font-size: 14px;
  background-color: ${(props) => props.color};
`;
